<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Revenue</b-card-title>
      <feather-icon
        icon="SettingsIcon"
        size="18"
        class="text-muted cursor-pointer"
      />
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            Ano Atual
          </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span class="text-primary">{{ ticket.numeroDeProcessos }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50">
            Ano Anterior
          </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">$ </sup>
            <span>{{ ticket.numeroDeProcessos }}</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="ticketHistorico.chartOptions"
        :series="ticketHistorico.series"
      />
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from 'axios'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    ticketsMes: Array,
  },
  data() {
    return {

      ticket: [],
      ticketHistorico: {
        series: [
          {
            name: 'Ano Atual',
            data: [],
          },
          {
            name: 'Ano Anterior',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#d0ccff', '#ebe9f1'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },

  created() {
    console.log(this.ticketsMes)
    if (this.$parent.dadosCarregados) { // Verifique se os dados foram carregados em um.vue
      this.ticket = this.ticketsMes
    
    // Resto do seu código para processar os dados
    }
    for (let i = 0; i < this.ticket.length; i += 1) {
      this.ticketHistorico.series[0].data.push(this.ticket[i].numeroDeProcessos)
      this.ticketHistorico.series[1].data.push(this.ticket[i].numeroDeProcessos)
      this.ticketsMes.numeroDeProcessos = this.ticket[i].numeroDeProcessos
      this.ticketsMes.numeroDeProcessos = this.ticket[i].numeroDeProcessos

      this.ticketHistorico.chartOptions.xaxis.categories.push(this.ticket[i].ano_mes)
      this.ticketsMes.initial = this.ticket[0].numeroDeProcessos
    }
  },

}
</script>
