<template>
  <div>
    <b-row class="match-height">
      <b-col lg="4">
        <card-analytic-support-tracker />
      </b-col>
      <b-col lg="8">
        <card-analytic-revenue :tickets-mes="ticketsMes" />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import axios from 'axios'
import CardAnalyticSupportTracker from './analise/CardAnalyticSupportTracker.vue'
import CardAnalyticRevenue from './analise/CardAnalyticRevenue.vue'

export default {
  components: {
    BRow,
    BCol,

    CardAnalyticSupportTracker,
    CardAnalyticRevenue,
  },
  data() {
    return {

      dadosCarregados: false,
      ticketsMes: [],

    }
  },
  async created() {
    try {
      const response = await axios.get('/api/v1/analytics/tickets/month')
      this.ticketsMes = response.data

      // Agora, os dados estão disponíveis. Você pode processá-los aqui.
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error)
    }
  }

  ,
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
