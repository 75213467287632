<template>
  <b-card no-body>
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>{{ trackerData.title }}</b-card-title>
      <b-dropdown
        no-caret
        right
        :text="selectedDuration"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in trackerData.lastDays"
          :key="day"
          @click="updateSelectedDuration(day)"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ trackerData.totalTicket }}
          </h1>
          <b-card-text>Tickets</b-card-text>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptions"
            :series="supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
            Tickets Novos
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.newTicket }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Tickets Pendentes
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.openTicket }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Tickets Fechados
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ trackerData.responseTime }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from 'axios'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  data() {
    return {
      selectedDuration: 'Últimos 7 Dias',
      trackerData: {},
      supportTrackerRadialBar: {
        series: [],
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Tickets Completos'],
        },
      },
    }
  },
  created() {
    this.getTicket(7)
  },
  methods: {

    getTicket(days) {
      const tickets = {}
      const supportTracker = {
        title: 'Ticker Tracker',
        lastDays: ['Últimos 7 Dias', 'Últimos 15 Dias', 'Último Mês', 'Último Ano'],
        totalTicket: 0,
        newTicket: 0,
        openTicket: 0,
        responseTime: 0,
      }
      const apiUrl = `/api/v1/analytics/tickets/${days}`

      axios.get(apiUrl)
        .then(res => {
          tickets.title = supportTracker.title
          tickets.lastDays = supportTracker.lastDays
          tickets.totalTicket = res.data.totalCriados
          tickets.newTicket = res.data.totalCriados
          tickets.openTicket = res.data.totalPendentes
          tickets.responseTime = res.data.totalTerminados
          this.trackerData = tickets

          this.supportTrackerRadialBar.series = [res.data.percentagemTerminados]
        })
        .catch(error => {
          // Lide com erros aqui
        })
    },
    updateSelectedDuration(duration) {
      this.selectedDuration = duration
      if (duration === 'Últimos 7 Dias') {
        this.getTicket(7)
      } else if (duration === 'Últimos 15 Dias') {
        this.getTicket(15)
      } else if (duration === 'Último Mês') {
        this.getTicket(31)
      } else if (duration === 'Último Ano') {
        this.getTicket(365)
      }
    },
  },
}
</script>
